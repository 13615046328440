li {
  list-style: none;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 0 2px 1px rgba(0, 0, 0, 0.2);
}
.hero .Typewriter__wrapper,
.hero .Typewriter__cursor {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.terminal .Typewriter__wrapper,
.terminal .Typewriter__cursor {
  color: #99ff99;
  font-size: 1rem;
}
.contact input,
.contact textarea {
  padding: 8px;
  border-radius: 4px;
}
.terminal {
  background-color: #000;
  border-radius: 8px;
  z-index: 20;
  position: relative;
  height: 400px;
  width: calc(100% - 60px);
}
.terminal-top {
  position: absolute;
  top: -30px;
  left: 0;
  background-color: #e5e5e5;
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.contact-card {
  border-radius: 4px;
}
.login {
  background-color: #e7e7e7;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  background-color: #fff;
  padding: 2rem;
  max-width: 400px;

  border-radius: 10px;
}
.login-box input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px 10px;
  margin: 10px 0;
  font-size: 0.85rem;
}
input:focus,
textarea:focus {
  outline: unset;
}
button {
  border: none;
}
.login-btn {
  background-color: #5e81e2;
  color: #fff;
  padding: 8px 1rem;
  border-radius: 4px;
  width: 100%;
}
.login-box img {
  width: 180px;
  height: 80px;
  transform: translate(40%, 0);
}
.forget {
  font-size: 0.85rem;
  color: #00546e;
  cursor: pointer;
}
.eye {
  position: absolute;
  margin: 1.4rem 0 0 -2.2rem;
}
/* CHAT */
.card {
  padding: 8px 18px;
  border-radius: 8px;
  margin-top: 18px;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.2);
}

.tab {
  display: flex;
  justify-content: space-around;
  background-color: #ccc;
  padding: 10px;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.2);
}
.tab li {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.content-card {
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.2);
  width: 220px;
  height: 80vh;
  padding: 15px;
}
.chat-input {
  width: 100%;
  outline: none;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.2);
  padding: 12px 40px 12px 12px;
}
.prod-input input {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  margin-top: 14px;
  width: 100%;
}
.login-input input {
  padding: 8px;
  width: 300px;
  background-color: #efecec;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.login-eye {
  position: absolute;
  margin-top: -10px;
}
